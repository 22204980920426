



































import {
  SfSidebar, SfList, SfMenuItem,
} from '@storefront-ui/vue';
import {
  defineComponent, useRouter, useContext, computed,
} from '@nuxtjs/composition-api';
import { useUiHelpers, useUiState } from '~/composables';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import { CategoryTree } from '~/modules/GraphQL/types';
import { useMobileCategoryTree } from './logic';

export default defineComponent({
  components: {
    SfSidebar,
    SfList,
    SfMenuItem,
  },
  setup() {
    const { isMobileMenuOpen, toggleMobileMenu } = useUiState();
    const { getCatLink } = useUiHelpers();
    const router = useRouter();
    const app = useContext();

    const { categoryAncestors: initialHistory, categoryTree } = useTraverseCategory();

    const navigate = (category: CategoryTree) => {
      toggleMobileMenu();
      const path = app.localePath(getCatLink(category) as string);
      router.push(path);
    };

    // A category with no child categories can't be entered into - it can only navigated to
    const initialHistoryWithSnippedSubcategoryLessTail = initialHistory.value.at(-1)?.children?.length
      ? initialHistory.value
      : initialHistory.value.slice(0, -1);

    const {
      current: currentCategory, history, currentItems, onGoCategoryUp, onGoCategoryDown,
    } = useMobileCategoryTree(initialHistoryWithSnippedSubcategoryLessTail);

    const itemsIncludedInMenu = computed(() => {
      const topLevelItems = categoryTree.value.children;
      const maybeCurrentCategoryItems = currentItems.value || topLevelItems;

      return maybeCurrentCategoryItems.filter((item) => item.include_in_menu);
    });

    return {
      currentCategory,
      onGoCategoryUp,
      onGoCategoryDown,
      history,
      navigate,
      isMobileMenuOpen,
      toggleMobileMenu,
      itemsIncludedInMenu,
    };
  },
});
